import types from '@/store/mutationTypes';

const resize = (store) => {
  window.addEventListener('resize', () => {
    store.commit(types.SET_SCREEN_SIZE, window.innerWidth);
  });
};
const initResize = (store) => {
  removeResize();
  store.commit(types.SET_SCREEN_SIZE, window.innerWidth);
  resize(store);
};
const removeResize = () => {
  window.removeEventListener('resize', resize);
};

export { resize, initResize, removeResize };
