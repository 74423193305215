import { merge } from 'lodash';

export default {
  SET_LOADER_STATE(state, value) {
    state.ui.isLoaderActive = value;
  },
  SET_SCREEN_SIZE(state, payload) {
    state.screenSize = payload;
  },
  SET_CMS_CONFIG(state, value) {
    state.cmsConfig = value;
  },
  SET_STAGE(state, value) {
    state.game.currentStage = value;
  },
  SET_RACE_ID(state, value) {
    state.game.raceId = value;
  },
  SET_EVENT_TIME(state, value) {
    state.game.eventTime[value.type] = value.time;
  },
  SET_IS_LAST_COUNTDOWN(state, value) {
    state.isLastCountdown = value;
  },
  SET_COUNTDOWN_TIME(state, value) {
    state.game.countdownTime = value;
  },
  SET_VIDEO_DATA(state, value) {
    state.game.videoData = value;
  },
  SET_GAME_ODDS(state, value) {
    state.game.offer = value;
  },
  SET_GAME_RESULTS(state, value) {
    state.game.results = value;
  },
  SET_WINNER_RESULTS(state, value) {
    state.game.results.winner = value;
  },
  SET_KNOCKOUT_RESULTS(state, value) {
    state.game.results.knockout = value;
  },
  SET_GAME_STATE_MODE(state, value) {
    state.game.stateMode = value;
  },
  SET_DELAY(state, value) {
    state.game.delay = value;
  },
  SET_TOURNAMENT_ID_TODAY(state, value) {
    state.game.tournamentIdToday = value;
  },
  SET_IS_MOBILE(state, payload) {
    state.isMobile = payload;
  },
  SET_VIDEO_ID(state, value) {
    state.game.videoId = value;
  },
  SET_RACE_START_TIME(state, value) {
    state.game.raceStartTime = value;
  },
  SET_EVENT_START_TIME(state, value) {
    state.game.eventStartTime = value;
  },
  SET_INFO_MESSAGE(state, value) {
    state.ui.infoMessage = value;
  },
  SET_SHOULD_IGNORE_SOCKET_MESSAGES(state, value) {
    state.shouldIgnoreSocketMessages = value;
  },
  UPDATE_TRANSLATIONS(state, value) {
    state.translations = merge(state.translations, value);
  },
  CLIENT_CONFIG(state, value) {
    state.clientConfig = value;
  },
};
