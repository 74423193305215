<template>
  <div class="countdown-content screen-content">
    <TransitionGroup name="fade">
      <!-- HEAD TO HEAD -->
      <div
        class="countdown-section head-to-head"
        :class="{ active: activeSection === 'winner' }"
        key="headToHead"
      >
        <div class="screen-title-wrap">
          <div class="screen-title">{{ store.getters.getTranslation('headToHead') }}</div>
          <div class="screen-subtitle">
            <span>{{ store.getters.getTranslation('raceNo') }}</span
            >&nbsp;
            <span class="race-id">{{ winnerData.raceId }}</span>
          </div>
        </div>
        <div
          class="screen-item-wrap"
          v-for="(competitor, idx) in winnerData.competitors"
          :class="idx === 0 ? 'left' : 'right'"
          :key="idx"
        >
          <div class="screen-item">
            <div class="screen-item-header">
              <div class="id">{{ competitor.id }}</div>
              <div class="name">{{ store.getters.getTranslation(`racer_${competitor.id}`) }}</div>
            </div>
            <div class="screen-item-content-top">
              <div class="title">{{ store.getters.getTranslation('raceWinner') }}</div>
              <div class="content">
                {{ competitor.odds && formatOdds(competitor.odds[winnerData.stage]) }}
              </div>
            </div>
            <div class="screen-item-content-middle">
              <div class="title">{{ store.getters.getTranslation('latestRaces') }}</div>
              <div class="content">
                <span
                  class="content-item"
                  v-for="(result, ridx) in competitor.lastResults"
                  :class="result.won ? 'race-won' : 'race-lost'"
                  :key="ridx"
                >
                  &#9734;
                </span>
              </div>
            </div>
            <div class="screen-item-content-bottom">
              <div class="title" v-if="winnerData.stage !== 'finals' && !maxWeb">
                {{ store.getters.getTranslation('tournamentOutrights') }}
              </div>
              <div class="title" v-else-if="winnerData.stage !== 'finals' && maxWeb">
                {{ store.getters.getTranslation('outrights') }}
              </div>
              <div class="content">
                <div class="content-item" v-if="competitor.odds.eighthFinals">
                  <div class="content-item-label">
                    {{ store.getters.getTranslation(phaseNameGetter('semiFinalist')) }}
                  </div>
                  <div class="content-item-value">
                    {{ formatOdds(competitor.odds.quarterFinals) }}
                  </div>
                </div>
                <div class="content-item" v-if="competitor.odds.quarterFinals">
                  <div class="content-item-label">
                    {{ store.getters.getTranslation(phaseNameGetter('finalist')) }}
                  </div>
                  <div class="content-item-value">
                    {{ formatOdds(competitor.odds.semiFinals) }}
                  </div>
                </div>
                <div class="content-item" v-if="competitor.odds.semiFinals">
                  <div class="content-item-label">
                    {{ store.getters.getTranslation(phaseNameGetter('winner')) }}
                  </div>
                  <div class="content-item-value">
                    {{ formatOdds(competitor.odds.finals) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screen-item-image" :class="`racer-${competitor.id}`"></div>
        </div>
      </div>
      <!-- RACE SCHEDULE -->
      <div
        class="countdown-section race-schedule"
        :class="{ active: activeSection === 'schedule' }"
        key="raceSchedule"
      >
        <div class="section-content">
          <!-- SECTION TITLE-SUBTITLE -->
          <div class="screen-title-wrap">
            <div class="screen-title">{{ store.getters.getTranslation('raceSchedule') }}</div>
            <div class="screen-subtitle">
              <span>{{ store.getters.getTranslation('tournamentNo') }}</span
              >&nbsp;
              <span>{{ tournamentIdToday }}</span>
            </div>
          </div>
          <!-- HEAD-TO-HEAD TABLE -->
          <div class="races-list-wrap">
            <ul class="races-list left" v-if="scheduleData.left.length">
              <li v-for="(race, idx) in scheduleData.left" :key="`race-${idx}`" class="race-item">
                <div class="race-start-time">
                  {{
                    idx === 0
                      ? store.getters.getTranslation('raceNow')
                      : store.getters.getTranslation(race.tournamentStage)
                  }}
                </div>
                <div class="race-details">
                  <div class="item-cell racer-id racer-1-id">
                    {{ race.competitor1.id }}
                  </div>
                  <div v-if="maxWeb" class="item-cell racer-name">
                    {{
                      getAbbreviatedFirstName(
                        store.getters.getTranslation(`racer_${race.competitor1.id}`)
                      )
                    }}
                  </div>
                  <div v-else class="item-cell racer-name racer-1-name">
                    {{ store.getters.getTranslation(`racer_${race.competitor1.id}`) }}
                  </div>
                  <div class="item-cell versus-cell">VS</div>
                  <div v-if="maxWeb" class="item-cell racer-name">
                    {{
                      getAbbreviatedFirstName(
                        store.getters.getTranslation(`racer_${race.competitor2.id}`)
                      )
                    }}
                  </div>
                  <div v-else class="item-cell racer-name racer-2-name">
                    {{ store.getters.getTranslation(`racer_${race.competitor2.id}`) }}
                  </div>
                  <div class="item-cell racer-id racer-2-id">
                    {{ race.competitor2.id }}
                  </div>
                </div>
                <div class="race-odds">
                  <div class="item-cell"></div>
                  <div class="item-cell racer-odds racer-1-odds">
                    {{ formatOdds(race.competitor1.odds) }}
                  </div>
                  <div class="item-cell"></div>
                  <div class="item-cell racer-odds racer-2-odds">
                    {{ formatOdds(race.competitor2.odds) }}
                  </div>
                  <div class="item-cell"></div>
                </div>
              </li>
            </ul>
            <ul class="races-list right" v-if="scheduleData.right.length">
              <li v-for="(race, idx) in scheduleData.right" :key="`race-${idx}`" class="race-item">
                <div class="race-start-time">
                  {{ store.getters.getTranslation(race.tournamentStage) }}
                </div>
                <div class="race-details">
                  <div class="item-cell racer-id racer-1-id">
                    {{ race.competitor1.id }}
                  </div>
                  <div v-if="maxWeb" class="item-cell racer-name racer-1-name">
                    {{
                      getAbbreviatedFirstName(
                        store.getters.getTranslation(`racer_${race.competitor1.id}`)
                      )
                    }}
                  </div>
                  <div v-else class="item-cell racer-name racer-1-name">
                    {{ store.getters.getTranslation(`racer_${race.competitor1.id}`) }}
                  </div>
                  <div class="item-cell versus-cell">VS</div>
                  <div v-if="maxWeb" class="item-cell racer-name racer-2-name">
                    {{
                      getAbbreviatedFirstName(
                        store.getters.getTranslation(`racer_${race.competitor2.id}`)
                      )
                    }}
                  </div>
                  <div v-else class="item-cell racer-name racer-2-name">
                    {{ store.getters.getTranslation(`racer_${race.competitor2.id}`) }}
                  </div>
                  <div class="item-cell racer-id racer-2-id">
                    {{ race.competitor2.id }}
                  </div>
                </div>
                <div class="race-odds">
                  <div class="item-cell"></div>
                  <div class="item-cell racer-odds racer-1-odds">
                    {{ formatOdds(race.competitor1.odds) }}
                  </div>
                  <div class="item-cell"></div>
                  <div class="item-cell racer-odds racer-2-odds">
                    {{ formatOdds(race.competitor2.odds) }}
                  </div>
                  <div class="item-cell"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- OUTRIGHTS -->
      <div
        class="countdown-section outrights"
        :class="{ active: activeSection === 'outrights' }"
        key="outrights"
      >
        <div class="section-content">
          <div class="screen-title-wrap">
            <div class="screen-title">{{ store.getters.getTranslation('outrights') }}</div>
            <div class="screen-subtitle">
              <span>{{ store.getters.getTranslation('tournamentNo') }}</span
              >&nbsp;
              <span>{{ tournamentIdToday }}</span>
            </div>
          </div>
          <div class="racers-list-wrap">
            <ul class="racers-list left" v-if="outrightsData.left.length">
              <li class="racers-list-caption labels">
                <div class="item-cell"></div>
                <div class="item-cell"></div>
                <div class="item-cell">
                  {{ store.getters.getTranslation(phaseNameGetter('semiFinalist')) }}
                </div>
                <div class="item-cell">
                  {{ store.getters.getTranslation(phaseNameGetter('finalist')) }}
                </div>
                <div class="item-cell">
                  {{ store.getters.getTranslation(phaseNameGetter('winner')) }}
                </div>
              </li>
              <li
                v-for="(racer, idx) in outrightsData.left"
                :key="`racer-${idx}`"
                class="racers-list-item"
                :class="{ 'racer-out': isOddsEmpty(racer.odds) }"
              >
                <div class="item-cell">{{ racer.id }}</div>
                <div class="item-cell racer-name">
                  {{ store.getters.getTranslation(`racer_${racer.id}`) }}
                </div>
                <template v-if="!isOddsEmpty(racer.odds)">
                  <div class="item-cell">{{ formatOdds(racer.odds.quarterFinals) }}</div>
                  <div class="item-cell">{{ formatOdds(racer.odds.semiFinals) }}</div>
                  <div class="item-cell">{{ formatOdds(racer.odds.finals) }}</div>
                </template>
                <template v-else>
                  <div class="item-cell"></div>
                  <div class="item-cell"></div>
                  <div class="item-cell uppercase out-text">
                    {{ store.getters.getTranslation('racerOut') }}
                  </div>
                </template>
              </li>
            </ul>
            <ul class="racers-list right" v-if="outrightsData.right.length">
              <li class="racers-list-caption labels">
                <div class="item-cell"></div>
                <div class="item-cell"></div>
                <div class="item-cell">
                  {{ store.getters.getTranslation(phaseNameGetter('semiFinalist')) }}
                </div>
                <div class="item-cell">
                  {{ store.getters.getTranslation(phaseNameGetter('finalist')) }}
                </div>
                <div class="item-cell">
                  {{ store.getters.getTranslation(phaseNameGetter('winner')) }}
                </div>
              </li>
              <li
                v-for="(racer, idx) in outrightsData.right"
                :key="`racer-${idx}`"
                class="racers-list-item"
                :class="{ 'racer-out': isOddsEmpty(racer.odds) }"
              >
                <div class="item-cell">{{ racer.id }}</div>
                <div class="item-cell">{{ store.getters.getTranslation(`racer_${racer.id}`) }}</div>
                <template v-if="!isOddsEmpty(racer.odds)">
                  <div class="item-cell">{{ formatOdds(racer.odds.quarterFinals) }}</div>
                  <div class="item-cell">{{ formatOdds(racer.odds.semiFinals) }}</div>
                  <div class="item-cell">{{ formatOdds(racer.odds.finals) }}</div>
                </template>
                <template v-else>
                  <div class="item-cell"></div>
                  <div class="item-cell"></div>
                  <div class="item-cell uppercase out-text">
                    {{ store.getters.getTranslation('racerOut') }}
                  </div>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
// Imports
// General
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { first, isEmpty } from 'lodash';
import { useStore } from 'vuex';
// Other
import { formatOdds, formatPhaseName, getAbbreviatedFirstName } from '@/utility';

// Refs
const store = useStore();
const winnerData = ref({ competitors: [], raceId: '', stage: '' });
const scheduleData = ref({ left: [], right: [] });
const outrightsData = ref({ left: [], right: [] });
const changeScreenInterval = ref();
const activeSection = ref();

// Getters
const tournamentIdToday = computed(() => store.getters.tournamentIdToday);
const game = computed(() => store.getters.game);
const ui = computed(() => store.getters.ui);
const countdownTime = computed(() => store.getters.countdownTime);
const lastCountdownTime = computed(() => store.getters.lastCountdownTime);
const screenSize = computed(() => store.getters.screenSize);
const isFinalRound = computed(() => store.getters.isFinalRound);
const isPenultimateRoundResultsStage = computed(() => store.getters.isPenultimateRoundResultsStage);
// Computed
const showcaseTime = computed(() =>
  countdownTime.value > lastCountdownTime.value ? countdownTime.value - lastCountdownTime.value : 20
);
const maxWeb = computed(() => screenSize.value <= 896);
const showcaseItemTime = computed(() => ui.value.showcaseItemTime);
const sections = computed(() => {
  const defaultSections = ['winner', 'schedule', 'outrights'];

  return hideOutrights.value ? defaultSections.slice(0, 2) : defaultSections;
});
const hideOutrights = computed(() => isPenultimateRoundResultsStage.value || isFinalRound.value);
// Methods
const isOddsEmpty = (odds) => {
  return !odds || isEmpty(odds);
};

const phaseNameGetter = computed(() => (phase) => formatPhaseName(phase, screenSize.value));

const setActiveScreen = (screen) => {
  activeSection.value = sections.value[screen] || first(sections.value);
  return activeSection.value;
};

const shiftScreens = () => {
  stopScreenShift();
  let currentScreenIndex = 0;

  if (showcaseTime.value <= showcaseItemTime.value) {
    setActiveScreen(0);
    return;
  }

  changeScreenInterval.value = setInterval(() => {
    currentScreenIndex += 1;
    if (!sections.value[currentScreenIndex]) {
      if (ui.value.showcaseLoop) {
        currentScreenIndex = 0;
      } else {
        stopScreenShift();
      }
    }
    setActiveScreen(currentScreenIndex);
  }, showcaseItemTime.value * 1000);

  setActiveScreen(0);
};

const stopScreenShift = () => {
  clearInterval(changeScreenInterval.value);
  changeScreenInterval.value = null;
};

const compareIds = ({ id: firstId }, { id: secondId }) => {
  if (firstId < secondId) return -1;
  if (firstId > secondId) return 1;
  return 0;
};

const formatWinnerData = () => {
  winnerData.value.raceId = game.value.raceId;
  winnerData.value.stage = game.value.offer.nextEvent.stage;
  winnerData.value.competitors = [
    game.value.offer.nextEvent.competitor1,
    game.value.offer.nextEvent.competitor2,
  ];
};
/**
 * Fill scheduleData.left and scheduleData.right with offer.schedule races objects
 * But append the appropriate tournamentStage for each object
 * Fill the left container until it reaches the length of 4
 * Then fill continue filling the right container
 */
const formatScheduleData = () => {
  const { schedule } = game.value.offer;
  scheduleData.value.left = [];
  scheduleData.value.right = [];

  Object.keys(schedule).forEach((stage) => {
    const stageRaces = schedule[stage];

    stageRaces.forEach((race) => {
      // Fill the left container with races until it reaches 4 rows
      const container = scheduleData.value.left.length < 4 ? 'left' : 'right';
      const modifiedRace = { ...race, tournamentStage: stage };

      scheduleData.value[container].push(modifiedRace);
    });
  });
};

/**
 * Fill outrightsData.left and outrightsData.right with offer.competitors races objects
 * But append the appropriate tournamentStage for each object
 * Fill the left container until it reaches the length of 4
 * Then fill continue filling the right container
 */
const formatOutrightsData = () => {
  const outrights = game.value.offer.competitors.sort(compareIds);
  outrightsData.value.left = [];
  outrightsData.value.right = [];

  outrights.forEach((competitor) => {
    // Fill the left container with races until it reaches 8 rows
    const container = outrightsData.value.left.length < 8 ? 'left' : 'right';
    outrightsData.value[container].push(competitor);
  });
};

onBeforeMount(() => {
  formatWinnerData();
  formatScheduleData();
  formatOutrightsData();
});

// Lifecycle hooks
onMounted(async () => {
  setTimeout(shiftScreens, 2500);
});

onUnmounted(() => stopScreenShift());
</script>

<style lang="scss">
// HEAD-TO-HEAD SECTION
.head-to-head {
  .screen-item-content-bottom .content-item-label {
    text-wrap: wrap;
  }
}

.countdown-section {
  opacity: 0;
  transition: opacity 0.5s;

  &.active {
    opacity: 1;
  }
}
.screen-item-wrap {
  position: absolute;
  top: 0;
  width: 31vw;
  height: 100%;

  &.left {
    left: 2vw;
  }
  &.right {
    right: 2vw;
  }
}
.screen-item {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  background-color: rgba($primary-bg-color, 0.2);
  font-size: get-vw(16px);
  color: rgba(#fff, 0.5);
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease-out, opacity 1.5s;
  opacity: 0;
  overflow: hidden;
}
.left .screen-item {
  transform: translateX(-35%) translateZ(0);
}
.right .screen-item {
  transform: translateX(35%) translateZ(0);
}
.active {
  .screen-item {
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    opacity: 1;
    transform: translateX(0%) translateZ(0);
    transition-delay: 0.5s;
  }
  .right .screen-item {
    transition-delay: 0.8s;
  }
}

.screen-item-content-middle {
  .content-item {
    display: inline-block;
    position: relative;
    font-size: get-vw(34px);
    color: $highlight-color;

    &.race-won:after {
      content: '\2605';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.screen-item-image {
  position: absolute;
  bottom: 20vh;
  width: get-vw(450px);
  aspect-ratio: 2 / 0.85;
  background: {
    repeat: no-repeat;
    position: 0 -5%;
    size: cover;
  }

  @for $i from 1 through 20 {
    &.racer-#{$i} {
      background-image: url('../assets/h2h/racer-#{$i}.png');
    }
  }
}

.active .screen-item-image {
  animation: verticalSprite 1.3s steps(36) forwards;
}

.left .screen-item-image {
  transform: scaleX(-1) translateZ(0);
  right: -50%;
}

.right .screen-item-image {
  animation-delay: 0.25s;
  left: -50%;
}

// RACE SCHEDULE SECTION
.races-list-wrap {
  position: relative;
  top: 19vh;
  width: 70vw;
  height: auto;
  margin: 0 auto;
  text-align: center;
  color: $primary-text-color;
}

.races-list {
  display: inline-block;
  width: 55%;
  height: 100%;
  margin: 0 1vw;
  vertical-align: top;
}

.race-item {
  width: 100%;
  height: auto;
  margin-bottom: 1.2vh;
}

.race-start-time {
  width: 100%;
  height: 4vh;
  line-height: 5vh;
  text-align: left;
  text-transform: uppercase;
}

.right .race-start-time {
  text-align: right;
}

.race-details,
.race-odds {
  display: table;
  width: 100%;
  height: get-vh(36px);
  background: $highlight-color;

  .item-cell {
    display: table-cell;
    vertical-align: middle;

    &:nth-child(1),
    &:nth-child(5) {
      width: get-vw(45px);
    }

    &:nth-child(3) {
      opacity: 0.6;
    }

    &:nth-child(2),
    &:nth-child(4) {
      width: 30%;
    }
  }

  .racer-id {
    font-weight: 700;
  }
}

.race-odds {
  height: get-vh(48px);
  background: rgba($primary-bg-color, 0.6);
  font-size: get-vh(32px);
}

// OUTRIGHTS SECTION
.racers-list-wrap {
  position: relative;
  top: 25vh;
  width: 82vw;
  height: auto;
  margin: 0 auto;
  text-align: center;
}

.racers-list {
  display: inline-block;
  width: 48.5%;
  height: 100%;

  &.left {
    float: left;
  }

  &.right {
    float: right;
  }
}
.racers-list-caption,
.racers-list-item {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: get-vh(40px);

  .item-cell {
    display: table-cell;
    vertical-align: middle;
    font-size: get-vw(14px);
    text-transform: capitalize;
    overflow: hidden;

    &:nth-child(1) {
      width: get-vw(40px);
    }

    &:nth-child(2) {
      position: relative;
      padding-left: 0.5vw;
      text-align: left;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 18%;
    }
  }
}
.racers-list-caption {
  height: get-vw(25px);
  color: $secondary-text-color;
}
.racers-list-item {
  margin-bottom: 1.25vh;
  background: rgba(0, 9, 0, 0.5);
  color: $primary-text-color;

  &.racer-out {
    opacity: 0.4;
  }

  .item-cell {
    font-size: get-vw(18px);

    &:nth-child(1) {
      background: $highlight-color;
    }
  }
}

@media screen and (min-width: 0px) {
  .head-to-head {
    .screen-title-wrap {
      .screen-title {
        font-size: get-vw(14px, 360);
      }
      .screen-subtitle {
        font-size: get-vw(12px, 360);
      }
    }
    .screen-item-wrap {
      .screen-item {
        .screen-item-content-top {
          color: rgba(#fff, 0.5);
          .content {
            font-size: get-vw(24px, 360);
          }
          .title {
            font-size: get-vw(10px, 360);
          }
        }
        .screen-item-content-middle {
          .title {
            font-size: get-vw(10px, 360);
          }
        }

        .screen-item-content-bottom {
          .title {
            font-size: get-vw(10px, 360);
          }
          .content {
            .content-item {
              .content-item-value {
                font-size: get-vw(11px, 360);
              }
              .content-item-label {
                font-size: get-vw(10px, 360);
              }
            }
          }
        }
      }
    }
    .screen-item-image {
      bottom: 18vh;
      width: get-vh(200px, 360);
    }
  }

  .race-schedule {
    .section-content {
      top: 0px;
      bottom: 2vh;
    }
    .screen-title-wrap {
      top: 3vh;
      .screen-title {
        font-size: get-vw(14px, 360);
      }
      .screen-subtitle {
        display: none;
      }
    }
    .races-list-wrap {
      top: 12vh;
      width: 74vw;
      display: flex;
      justify-content: center;
      .races-list {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        .race-item {
          margin-bottom: 0px;
          .race-start-time {
            font-size: 0.625rem;
            margin-bottom: 1.5px;
          }
        }
        .racer-name {
          display: none;
        }
      }
    }

    .racer-name {
      font-size: 0.625rem;
    }

    .race-start-time {
      font-size: 0.625rem;
    }

    .racer-id {
      font-size: 0.75rem;
    }

    .race-details {
      display: flex;
      align-items: center;
      height: 1rem;

      .item-cell {
        width: 0;
        flex: 1;
        &.versus-cell {
          font-size: 0.75rem;
          flex: 1;
          flex-basis: 0px;
        }
      }
    }

    .race-odds {
      display: flex;
      align-items: center;
      height: 1rem;
      .item-cell {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }

  .outrights {
    .section-content {
      top: 0px;
    }
    .racers-list-wrap {
      top: 10vh;
    }
    .screen-title-wrap {
      top: 1vh;
      .screen-title {
        font-size: 0.875rem;
      }
    }
    .racers-list-caption {
      margin-bottom: 0.2rem;
    }
    .racers-list-caption,
    .racers-list-item {
      display: flex;
      width: 100%;
      height: get-vh(44px);

      .item-cell {
        flex: 1;
        width: 18%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        text-transform: capitalize;
        text-wrap: wrap;
        overflow: hidden;

        &.out-text {
          flex: 1;
          font-weight: 500;
          width: 18%;
          text-transform: uppercase;
        }

        &:nth-child(1) {
          flex-basis: 20px;
          flex-grow: 0;
        }

        &:nth-child(2) {
          display: none;
        }
      }
    }

    .racers-list-caption.labels .item-cell {
      font-size: 0.625rem;
      font-weight: 500;
    }
  }
}

@media screen and (min-width: $breakpoint-mobile-md) {
  .head-to-head {
    .screen-title-wrap {
      .screen-title {
        font-size: get-vw(14px, 360);
      }
      .screen-subtitle {
        font-size: get-vw(12px, 360);
      }
    }
    .screen-item-wrap {
      .screen-item {
        .screen-item-content-top {
          .content {
            font-size: get-vw(24px, 480);
          }
          .title {
            font-size: get-vw(10px, 480);
          }
        }
        .screen-item-content-middle {
          .title {
            font-size: get-vw(10px, 480);
          }
        }
        .screen-item-content-bottom {
          .title {
            font-size: get-vw(10px, 480);
          }
          .content {
            .content-item {
              .content-item-value {
                font-size: get-vw(12px, 480);
              }
              .content-item-label {
                font-size: get-vw(10px, 480);
              }
            }
          }
        }
      }
    }
  }
  .race-schedule {
    .section-content {
      top: 0px;
      bottom: 2vh;
    }
    .races-list-wrap .races-list .racer-name {
      display: block;
    }
    .screen-title-wrap {
      .screen-title {
        font-size: 0.875rem;
      }
      .screen-subtitle {
        display: none;
      }
    }
    .races-list-wrap .races-list .race-item .race-start-time {
      font-size: 0.625rem;
    }

    .racer-id {
      font-size: 0.75rem;
    }

    .race-odds .item-cell {
      &:nth-child(1),
      &:nth-child(5) {
        display: block;
      }
    }

    .race-odds,
    .race-details {
      height: 1.375rem;
    }
  }

  .head-to-head .screen-item-image {
    bottom: 18vh;
    width: get-vw(220px, 480);
  }
  .outrights {
    .racers-list-item,
    .racers-list-caption {
      .item-cell {
        &:nth-child(2) {
          flex-basis: 15%;
          display: flex;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .head-to-head .screen-item-image {
    bottom: 22.5vh;
    width: get-vw(235px, 640);
  }
  .race-schedule {
    .races-list-wrap {
      top: 15vh;
      width: 74vw;
    }

    .racer-name,
    .versus-cell {
      font-size: get-vw(12px, 640);
    }

    .races-list-wrap .races-list .race-item .race-start-time {
      font-size: get-vw(20px);
    }

    .racer-id {
      font-size: get-vw(24px);
    }

    .race-details {
      height: 5.55vh;
    }
  }
}

@media screen and (min-width: $breakpoint-web-md) {
  .head-to-head {
    .screen-item-wrap {
      .screen-item {
        .screen-item-content-top {
          .content {
            font-size: get-vw(48px, 768);
          }

          .title {
            font-size: get-vw(12px, 768);
          }
        }

        .screen-item-content-middle {
          .content {
            .content-item {
              font-size: get-vw(16px, 768);
            }
          }

          .title {
            font-size: get-vw(12px, 768);
          }
        }

        .screen-item-content-bottom {
          .content {
            .content-item {
              .content-item-label {
                font-size: get-vw(12px, 768);
              }

              .content-item-value {
                font-size: get-vw(23px, 768);
              }
            }
            font-size: get-vw(48px, 768);
          }

          .title {
            font-size: get-vw(12px, 768);
          }
        }
      }
    }
  }
  .race-schedule {
    .racer-name,
    .versus-cell {
      font-size: get-vw(11px, 768);
    }
    .race-odds {
      height: get-vw(32px, 768);
    }
    .screen-title-wrap {
      .screen-title,
      .screen-subtitle {
        display: block;
        font-size: get-vw(22px, 768);
      }
    }
  }
  .outrights {
    .racers-list-wrap {
      top: 14vh;
    }
    .screen-title-wrap {
      .screen-title,
      .screen-subtitle {
        font-size: 1.625rem;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-web) {
  .race-schedule {
    .race-details {
      .item-cell {
        &.versus-cell {
          font-size: get-vw(18px);
        }
      }
    }
    .section-content {
      top: 0px;
      bottom: 2vh;
    }
    .races-list-wrap {
      top: 18vh;
    }
    .races-list-wrap .races-list .racer-name {
      display: block;
    }
    .screen-title-wrap .screen-title .screen-subtitle {
      font-size: get-vw(26px, 896);
    }

    .race-odds .item-cell {
      height: get-vw(32px, 896);
      &:nth-child(1),
      &:nth-child(5) {
        display: block;
      }
    }

    .screen-title-wrap {
      top: 5vh;
    }

    .screen-item-image {
      width: get-vw(521px);
      height: get-vw(213px);
    }

    .racer-name,
    .race-start-time {
      font-size: get-vw(18px);
    }
  }

  .head-to-head {
    .screen-item-image {
      bottom: 24vh;
      height: get-vw(216px);
    }
  }

  .screen-title-wrap {
    .screen-subtitle {
      font-size: get-vw(37px) !important;
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-sm) {
  .head-to-head .screen-item-image {
    bottom: 24vh;
    height: get-vw(204px);
  }
  .outrights {
    .section-content {
      top: 2vh;
    }
    .racers-list-wrap {
      top: 18vh;
    }
    .racers-list-caption,
    .racers-list-item {
      height: 40px;
      .item-cell {
        font-size: 1rem;
        &:nth-child(1) {
          flex-basis: 40px;
          flex-grow: 0;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop) {
  .head-to-head {
    .screen-item-wrap {
      .screen-item {
        .screen-item-content-top {
          .title {
            font-size: get-vw(16px, 1280);
          }

          .content {
            font-size: get-vw(64px, 1280);
          }
        }

        .screen-item-content-middle {
          .content {
            overflow: visible;
            .content-item {
              font-size: get-vw(24px, 1280);
            }
          }

          .title {
            font-size: get-vw(16px, 1280);
          }
        }
        .screen-item-content-bottom {
          .title {
            font-size: get-vw(16px, 1280);
          }

          .content {
            margin-top: 0px;
            .content-item {
              .content-item-label {
                font-size: get-vw(16px, 1280);
              }

              .content-item-value {
                font-size: get-vw(39px, 1280);
              }
            }
          }
        }
      }
    }

    .screen-item-image {
      bottom: 23vh;
      height: get-vw(204px);
    }
  }
  .outrights {
    .section-content {
      top: 2vh;
    }
    .racers-list-wrap {
      top: 18vh;
    }
    .screen-title-wrap {
      .screen-title,
      .screen-subtitle {
        font-size: get-vw(40px);
      }
    }
    .racers-list-caption.labels .item-cell {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-lg) {
  .outrights {
    .racers-list-caption,
    .racers-list-item {
      height: 60px;
      .item-cell {
        font-size: get-vw(24px, 1920);
      }
    }
    .screen-title-wrap {
      top: 5vh;
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-max) {
  .race-schedule {
    .section-content {
      top: 1.5vh;
    }

    .races-list-wrap {
      top: 19vh !important;
      width: 70vw;
    }

    .screen-title-wrap {
      top: 5vh;
    }
  }

  .outrights {
    .racers-list-caption,
    .racers-list-item {
      height: 60px;
      .item-cell {
        font-size: get-vw(24px, 1920);
      }
    }
    .screen-title-wrap {
      top: 5vh;
    }
  }
}
</style>
