export default (locale) => {
  switch (locale) {
    case 'sr-Latn':
    case 'sr':
      return import(/* webpackChunkName: "i18n.common.sr-Latn" */ './sr-Latn');
    case 'ro':
      return import(/* webpackChunkName: "i18n.common.ro" */ './ro');
    default:
      return import(/* webpackChunkName: "i18n.common" */ './en');
  }
};
