export default {
  CLIENT_CONFIG: 'CLIENT_CONFIG',
  SET_CMS_CONFIG: 'SET_CMS_CONFIG',
  SET_COUNTDOWN_TIME: 'SET_COUNTDOWN_TIME',
  SET_DELAY: 'SET_DELAY',
  SET_EVENT_START_TIME: 'SET_EVENT_START_TIME',
  SET_EVENT_TIME: 'SET_EVENT_TIME',
  SET_GAME_ODDS: 'SET_GAME_ODDS',
  SET_GAME_RESULTS: 'SET_GAME_RESULTS',
  SET_GAME_STATE_MODE: 'SET_GAME_STATE_MODE',
  SET_INFO_MESSAGE: 'SET_INFO_MESSAGE',
  SET_IS_LAST_COUNTDOWN: 'SET_IS_LAST_COUNTDOWN',
  SET_KNOCKOUT_RESULTS: 'SET_KNOCKOUT_RESULTS',
  SET_LOADER_STATE: 'SET_LOADER_STATE',
  SET_RACE_ID: 'SET_RACE_ID',
  SET_RACE_START_TIME: 'SET_RACE_START_TIME',
  SET_SCREEN_SIZE: 'SET_SCREEN_SIZE',
  SET_SHOULD_IGNORE_SOCKET_MESSAGES: 'SET_SHOULD_IGNORE_SOCKET_MESSAGES',
  SET_STAGE: 'SET_STAGE',
  SET_TOURNAMENT_ID_TODAY: 'SET_TOURNAMENT_ID_TODAY',
  SET_VIDEO_DATA: 'SET_VIDEO_DATA',
  SET_VIDEO_ID: 'SET_VIDEO_ID',
  SET_WINNER_RESULTS: 'SET_WINNER_RESULTS',
  UPDATE_TRANSLATIONS: 'UPDATE_TRANSLATIONS',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
};
