<script setup>
// THIS COMPONENT DISPLAYS RACER IDS AND NAMES DURING A RACE
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';

// utility
import { getTimeDelay } from '@nsftx/games-visualization-sdk/src/utility';
import { getAbbreviatedFirstName, formatOdds } from '@/utility';

const store = useStore();

let remainingTimeInterval = null;
// ref
const showRaceInfo = ref(false);
const remainingTimeUntilVideoFinish = ref();

// getters
const leftCompetitor = computed(() => store.state.game.videoData.leftCompetitor);
const rightCompetitor = computed(() => store.state.game.videoData.rightCompetitor);
const eventStartTime = computed(() => store.state.game.eventStartTime);
const screenSize = computed(() => store.getters.screenSize);
/**
 * The duration of a stage (race in this example)
 */
const delay = computed(() => store.state.game.delay);

// computed
const leftCompetitorOdds = computed(() => formatOdds(leftCompetitor.value.odd));
const rightCompetitorOdds = computed(() => formatOdds(rightCompetitor.value.odd));
const isVideoFinishing = computed(() => remainingTimeUntilVideoFinish.value <= 0.75);
const maxMobile = computed(() => screenSize.value <= 640);

/**
 * If we arrive at the end of the video, don't bother showing race info
 */
const shouldInfoNotBeDisplayed = computed(() => remainingTimeUntilVideoFinish.value <= 4.5);

// methods
const getFormattedCompetitorName = (competitorId) => {
  const translatedName = store.getters.getTranslation(`racer_${competitorId}`);
  return maxMobile.value ? getAbbreviatedFirstName(translatedName) : translatedName;
};
const initRemainingVideoTime = () => {
  remainingTimeUntilVideoFinish.value = getTimeDelay(
    new Date(eventStartTime.value).getTime(),
    new Date().getTime(),
    delay.value
  );
};
const initRemainingTimeCountdown = () => {
  remainingTimeInterval = setInterval(() => {
    remainingTimeUntilVideoFinish.value -= 1;
  }, 1000);
};
const initRaceInfo = () => {
  if (shouldInfoNotBeDisplayed.value) return;

  setTimeout(() => {
    showRaceInfo.value = true;
  }, 200);
};

// lifecycle hooks
onMounted(() => {
  initRemainingVideoTime();
  initRaceInfo();
  initRemainingTimeCountdown();
});
onUnmounted(() => {
  clearInterval(remainingTimeInterval);
});
// watchers
watch(isVideoFinishing, (newValue) => {
  showRaceInfo.value = !newValue;
});
</script>

<template>
  <div>
    <Transition name="fade">
      <div v-if="showRaceInfo" class="race-info">
        <!-- LEFT COMPETITOR -->
        <div class="race-info__competitor-wrapper race-info__competitor-wrapper--left">
          <div class="race-info__competitor race-info__competitor--left">
            <!-- NAME -->
            <div class="race-info__name">
              <div class="race-info__name-text">
                {{ getFormattedCompetitorName(leftCompetitor.id) }}
              </div>
            </div>
            <!-- ID -->
            <div class="race-info__id">{{ leftCompetitor.id }}</div>
            <!-- ODDS -->
            <div class="race-info__odds">{{ leftCompetitorOdds }}</div>
            <div class="race-info__empty"></div>
          </div>
          <!-- CAR IMAGE -->
          <div
            class="race-info__car race-info__car--left"
            :class="`race-info__car--${leftCompetitor.id}`"
          ></div>
        </div>

        <!-- RIGHT COMPETITOR -->
        <div class="race-info__competitor-wrapper race-info__competitor-wrapper--right">
          <div class="race-info__competitor race-info__competitor--right">
            <!-- ID -->
            <div class="race-info__id">{{ rightCompetitor.id }}</div>
            <!-- NAME -->
            <div class="race-info__name">
              <div class="race-info__name-text">
                {{ getFormattedCompetitorName(rightCompetitor.id) }}
              </div>
            </div>
            <!-- ODDS -->
            <div class="race-info__odds">{{ rightCompetitorOdds }}</div>
            <div class="race-info__empty"></div>
          </div>
          <!-- CAR IMAGE -->
          <div
            class="race-info__car race-info__car--right"
            :class="`race-info__car--${rightCompetitor.id}`"
          ></div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
// some cars are wider than others
@mixin car-modifier--left {
  // especially wide cars
  &--20,
  &--19,
  &--14 {
    left: 0.1rem;
  }

  // a little wider
  &--18,
  &--15,
  &--10,
  &--8,
  &--1 {
    left: -0.35rem;
  }
}

@mixin car-modifier--right {
  &--20,
  &--19,
  &--14 {
    right: 0.1rem;
  }

  &--18,
  &--15,
  &--10,
  &--8,
  &--1 {
    right: -0.35rem;
  }
}
@keyframes rollOutLeft {
  0%,
  50% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rollOutRight {
  0%,
  50% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.race-info {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  justify-content: space-between;

  &__name-text {
    max-width: 16.7vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__competitor-wrapper {
    position: relative;
    min-width: 44%;
    &--left {
      .race-info {
        &__competitor {
          animation: rollOutLeft 1.8s forwards;
          animation-delay: 1.3s;
        }
        &__car {
          transform: scaleX(-1) translateZ(0) translateY(-50%);
          left: -0.85rem;
          top: 50%;
        }
      }
    }
    &--right {
      .race-info {
        &__competitor {
          animation: rollOutRight 1.8s forwards;
          animation-delay: 1.3s;
        }
        &__car {
          right: -0.85rem;
        }
      }
    }
  }

  &__competitor {
    opacity: 0;
    display: grid;
    font-size: 0.75rem;
    line-height: 0.75rem;
    height: 26px;

    &--left {
      grid-template-columns: 1fr auto;
      margin-left: 32%;

      .race-info {
        &__odds,
        &__empty {
          display: none;
        }
        &__odds {
          padding: 1px 0 1px 0.5rem;
        }
        &__name {
          padding: 1px 0 1px 0.5rem;
          text-align: center;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          text-wrap: wrap;
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          background-color: rgba(227, 12, 102, 1);
        }

        &__id {
          padding: 1px 0.5rem;
          border-top-right-radius: 2px;
          font-weight: 500;
          background-color: rgba(227, 12, 102, 1);
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          display: flex;
          align-items: center;
        }
      }
    }

    &--right {
      grid-template-columns: auto 1fr;
      margin-right: 32%;

      .race-info {
        &__name,
        &__id {
          background-color: rgba(227, 12, 102, 1);
        }

        &__id {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 1px 0 1px 0.5rem;
          font-weight: 500;
          border-top-left-radius: 2px;
        }

        &__name {
          padding: 1px 0.5rem;
          text-align: center;
          display: flex;
          align-items: center;
          text-wrap: wrap;
        }

        &__odds,
        &__empty {
          display: none;
        }
      }
    }
  }

  &__car {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2.25rem;
    aspect-ratio: 2 / 0.85;
    animation: verticalSprite 1.3s steps(36) forwards;
    animation-delay: 2s;
    background: {
      repeat: no-repeat;
      position: 0 -5%;
      size: cover;
    }
    @for $i from 1 through 20 {
      &--#{$i} {
        background-image: url('../assets/h2h/racer-#{$i}.png');
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile-md) {
  .race-info {
    bottom: 2rem;
    left: 0.5rem;
    right: 0.5rem;

    &__competitor-wrapper {
      min-width: 44%;

      &--left {
        .race-info__car {
          @include car-modifier--left;
        }
      }

      &--right {
        .race-info__car {
          @include car-modifier--right;
        }
      }
    }
    &__competitor {
      grid-template-rows: auto auto;
      line-height: 0.875rem;
      height: auto;

      &--left {
        margin-left: 25%;

        .race-info {
          &__name {
            justify-content: end;
          }
          &__odds,
          &__empty {
            background-color: rgba(0, 0, 0, 0.3);
            display: block;
          }

          &__odds {
            padding: 1px 0 1px 0.5rem;
            text-align: right;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }

          &__empty {
            border-bottom-right-radius: 2px;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
          }
        }
      }

      &--right {
        margin-right: 25%;

        .race-info {
          &__ {
            justify-content: start;
          }
          &__odds,
          &__empty {
            background-color: rgba(0, 0, 0, 0.3);
            display: block;
          }

          &__empty {
            border-bottom-left-radius: 2px;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }

          &__odds {
            padding: 1px 0 1px 0.5rem;
            text-align: left;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
          }
        }
      }
    }

    &__car {
      height: 50px;
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .race-info {
    bottom: 2rem;

    &__competitor-wrapper {
      min-width: 35%;
    }
    &__competitor {
      grid-template-rows: auto auto;
      line-height: 0.875rem;
      height: auto;

      &--left {
        margin-left: 25%;

        .race-info {
          &__name {
            padding-top: 2.75px;
            padding-bottom: 2.75px;
          }
          &__odds,
          &__empty {
            background-color: rgba(0, 0, 0, 0.3);
            display: block;
          }

          &__odds {
            padding: 1px 0 1px 0.5rem;
            font-size: 1.125rem;
            line-height: 1.3125rem;
            text-align: right;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }

          &__empty {
            border-bottom-right-radius: 2px;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
          }
        }
      }

      &--right {
        margin-right: 25%;

        .race-info {
          &__name {
            padding-top: 2.75px;
            padding-bottom: 2.75px;
          }
          &__odds,
          &__empty {
            background-color: rgba(0, 0, 0, 0.3);
            display: block;
          }

          &__empty {
            border-bottom-left-radius: 2px;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }

          &__odds {
            padding: 1px 0 1px 0.5rem;
            font-size: 1.125rem;
            line-height: 1.3125rem;
            text-align: left;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
          }
        }
      }
    }

    &__car {
      height: 56px;
    }
  }
}

@media screen and (min-width: $breakpoint-web-md) {
  .race-info {
    &__competitor-wrapper {
      min-width: 40%;
    }
    &__competitor {
      &--left {
        margin-left: 30%;

        .race-info {
          &__name {
            padding-top: 2.75px;
            padding-bottom: 2.75px;
          }

          &__odds {
            font-size: 1.5rem;
            line-height: 1.625rem;
          }
        }
      }

      &--right {
        margin-right: 30%;

        .race-info {
          &__name {
            padding-top: 2.75px;
            padding-bottom: 2.75px;
          }

          &__odds {
            font-size: 1.5rem;
            line-height: 1.625rem;
          }
        }
      }
    }

    &__car {
      height: 80px;
    }
  }
}

@media screen and (min-width: $breakpoint-web) {
  .race-info {
    &__competitor-wrapper {
      min-width: 40%;
    }
    &__competitor {
      &--left {
        margin-left: 30%;

        .race-info {
          &__name,
          &__id {
            font-size: 1.125rem;
            line-height: 1.33rem;
          }

          &__name {
            padding-top: 2.75px;
            padding-bottom: 2.75px;
          }

          &__odds {
            font-size: 1.5rem;
            padding-top: 3px;
            padding-bottom: 3px;
            line-height: 1.625rem;
          }
        }
      }

      &--right {
        margin-right: 30%;

        .race-info {
          &__name,
          &__id {
            font-size: 1.125rem;
            line-height: 1.33rem;
          }
          &__name {
            padding-top: 2.75px;
            padding-bottom: 2.75px;
          }

          &__odds {
            padding-top: 3px;
            padding-bottom: 3px;
            font-size: 1.5rem;
            line-height: 1.625rem;
          }
        }
      }
    }

    &__car {
      height: get-vw(85px, 896);
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-sm) {
  .race-info {
    bottom: 3rem;
    &__competitor-wrapper {
      min-width: 35%;
    }
    &__competitor {
      &--left {
        margin-left: 30%;

        .race-info {
          &__name,
          &__id {
            padding-top: 7.5px;
            padding-bottom: 7.5px;
            font-size: get-vw(18px, 1280);
            line-height: get-vw(21.33px, 1280);
          }

          &__name {
            padding-top: 2.75px;
            padding-right: 1rem;
            padding-bottom: 2.75px;
          }

          &__odds {
            padding-top: 3px;
            padding-bottom: 3px;
            padding-right: 1rem;
            font-size: get-vw(32px, 1280);
            line-height: get-vw(37.97px, 1280);
          }
        }
      }

      &--right {
        margin-right: 30%;

        .race-info {
          &__name,
          &__id {
            padding-top: 7.5px;
            padding-bottom: 7.5px;
            font-size: get-vw(18px, 1280);
            line-height: get-vw(21.33px, 1280);
          }
          &__name {
            padding-top: 2.75px;
            padding-left: 1rem;
            padding-bottom: 2.75px;
          }

          &__odds {
            padding-top: 3px;
            padding-bottom: 3px;
            font-size: 2rem;
            padding-left: 1rem;
            line-height: 2.37rem;
            font-size: get-vw(32px, 1280);
            line-height: get-vw(37.97px, 1280);
          }
        }
      }
    }

    &__car {
      height: get-vw(110px, 1280);
    }
  }
}
</style>
