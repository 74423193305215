<template>
  <div class="content-wrap">
    <component :is="currentStageComponent" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import StageCountdown from './StageCountdown';
import StageRace from './StageRace';
import StageResults from './StageResults';

const store = useStore();
const currentStage = computed(() => store.getters.currentStage);

const stageComponents = {
  countdown: StageCountdown,
  race: StageRace,
  result: StageResults,
  bettingDisabled: StageCountdown,
};

const currentStageComponent = computed(() => stageComponents[currentStage.value]);
</script>

<style lang="scss">
.content-wrap {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  height: calc(100vh - #{$header-height} - 3px);
  overflow: hidden;
  white-space: nowrap;
}
.screen-content {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-right: 10%;
}

@media screen and (min-width: $breakpoint-mobile) {
  .content-wrap {
    bottom: 0;
  }
}
</style>
