<template>
  <div class="race-content screen-content">
    <div class="player">
      <VideoPlayer
        v-if="isPlayerReady"
        :class="['vjs-custom-skin', isMobile ? 'mobile' : 'fill']"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        @ready="onPlayerReady($event)"
        @ended="onPlayerEnded($event)"
      />
    </div>
    <RaceInfo class="race-info-wrapper" />
  </div>
</template>

<script setup>
// Imports
// General
import { computed, onMounted, onUnmounted, onUpdated, ref } from 'vue';
// Components
import VideoPlayer from '@nsftx/games-visualization-sdk/src/components/VideoPlayer';
import RaceInfo from '@/components/RaceInfo';

// Utility
import { getTimeDelay } from '@nsftx/games-visualization-sdk/src/utility';

// Other
import { padStart, some, isEmpty } from 'lodash';
import { useStore } from 'vuex';

// Ref
const store = useStore();
const videoPlayer = ref(null);
const isPlayerReady = ref(false);
const playerOptions = ref({
  width: window.innerWidth,
  height: window.innerHeight,
  preload: 'auto',
  autoplay: true,
  loop: false,
  muted: true,
  language: 'en',
  techOrder: ['html5'],
  crossorigin: 'anonymous',
  cors: 'anonymous',
  controls: false,
  subtitles: null,
  sources: [
    {
      // type: 'application/x-mpegURL'
    },
    {
      // type: 'application/dash+xml'
    },
  ],
});
// Computed
// Getters
const isMobile = computed(() => store.state.isMobile);
const videosPath = computed(() => store.getters.ui.videosPath);
const raceStartTime = computed(() => store.getters.raceStartTime);
const videoId = computed(() => store.getters.videoId);
const eventStartTime = computed(() => store.state.game.eventStartTime);
const delay = computed(() => store.state.game.delay);
// Methods
const onPlayerReady = (player) => {
  player.currentTime(raceStartTime.value || 0);
};

const onPlayerEnded = () => store.dispatch('activateLoader');

const generateVideoPath = () => {
  if (!videoId.value) return;

  const { hlsManifestUrl, dashManifestUrl } = getDashAndHlsManifestUrls(videoId.value);

  playerOptions.value.sources[0].src = hlsManifestUrl;
  playerOptions.value.sources[0].type = 'application/x-mpegURL';
  playerOptions.value.sources[1].src = dashManifestUrl;
  playerOptions.value.sources[1].type = 'application/dash+xml';

  isPlayerReady.value = true;
};

const getDashAndHlsManifestUrls = (id) => {
  const gameAndBrandingRelativeFolderPath = 'vdr-enc-nb';
  const prefix = `${videosPath.value}/${gameAndBrandingRelativeFolderPath}`;
  const dashManifestUrl = `${prefix}/vdr-${padStart(id, 5, 0)}/manifest.mpd`;
  const hlsManifestUrl = `${prefix}/vdr-${padStart(id, 5, 0)}/manifest.m3u8`;

  return { hlsManifestUrl, dashManifestUrl };
};

const updatePlayer = () => {
  // length of video - remaining time to play
  const currentVideoMoment =
    delay.value -
    getTimeDelay(new Date(eventStartTime.value).getTime(), new Date().getTime(), delay.value);

  if (!currentVideoMoment) return;
  if (isEmpty(videoPlayer.value.player)) return;

  videoPlayer.value.player.currentTime(currentVideoMoment);
};

const onVisibilityChange = () => {
  if (some([document.hidden, !videoPlayer.value])) return;

  updatePlayer();
};
// Watchers

// Lifecycle hooks
onUpdated(() => {
  generateVideoPath();
});

onMounted(() => {
  document.addEventListener('visibilitychange', onVisibilityChange);
  generateVideoPath();
});

onUnmounted(() => {
  document.removeEventListener('visibilitychange', onVisibilityChange);
});
</script>
<style lang="scss" scoped>
.race-info-wrapper {
  height: 100%;
  position: relative;
}

.race-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.video-player.vjs-custom-skin {
  &.mobile {
    height: calc(100vh - #{$header-loader-height-sm});
    width: auto !important;
    aspect-ratio: 16 / 9;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
  &.fill {
    height: 100% !important;
    width: 100% !important;
    top: 0;
    left: 0;
    bottom: 0;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .video-player.vjs-custom-skin.mobile {
    height: calc(100vh - #{$header-loader-height-md});
  }
}

.content-item {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 100vh;
  background: #000;
  color: $primary-text-color;
  font-size: 48px;
  text-align: center;
}
</style>
