import { settings, getQueryParam } from '@/utility';

export default {
  // In the case when the parent is bouncing messages via the Bus servuce, we need to filter out
  // which types of messages we want to listen to, as the event is called 'message',
  // which is used for other stuff as well
  bouncedMessageTypesToListen: ['bettingDisabled', 'race', 'result', 'countdown'],
  // A rough estimate of the lengthiest time difference between two socket messages
  // depending on the current phase
  lengthiestMessageDelayPerStage: {
    bettingDisabled: 5,
    race: 25,
    result: 30,
    countdown: 60,
  },
  isMobile: null,
  clientConfig: {},
  cmsConfig: {},
  config: settings.app,
  debugMode: settings.app.debug,
  game: {
    countdownTime: 0,
    currentStage: '',
    delay: null,
    eventStartTime: null,
    eventTime: {
      countdown: 155,
      race: 20,
      results: 20,
    },
    offer: {},
    previousResults: [],
    raceId: 0,
    raceStartTime: 0,
    results: {},
    stateMode: true,
    tournamentIdToday: 0,
    videoData: {},
    videoId: null,
  },
  language: settings.app.language,
  messaging: settings.app.messaging,
  product: settings.product,
  screenSize: null,
  translations: settings.app.translations,
  shouldIgnoreSocketMessages: !!getQueryParam('ignoreSocketMessages'),
  ui: {
    bgVideo: settings.app.bgVideo,
    infoMessage: '',
    isLoaderActive: true,
    showcaseItemTime: 10,
    showcaseLoop: settings.app.showcaseLoop,
    videosPath: settings.app.videosPath,
  },
};
