<template>
  <div id="app">
    <AppWrapper />
  </div>
</template>

<script setup>
import { initBusService, initResize, removeResize, dateDifferenceInSeconds } from '@/utility';
import { onMounted, onBeforeMount, onBeforeUnmount, inject, computed } from 'vue';
import { some } from 'lodash';
import { useStore } from 'vuex';
import http from '@/api/http';
import getConfiguration from '@/utility/getConfiguration';
import AppWrapper from './components/AppWrapper';

const store = useStore();
const emitter = inject('emitter');
const isPostmessaging = computed(() => store.getters.isPostmessaging);
const bouncedMessageTypesToListen = computed(() => store.state.bouncedMessageTypesToListen);
const eventStartTime = computed(() => store.state.game.eventStartTime);
const lengthiestMessageDelay = computed(() => store.getters.lengthiestMessageDelay);

const initBus = (config) => {
  if (!config) console.warn('Config has not been passed to init bus service');

  const busService = initBusService(config);
  const { productId, productInstanceUuid } = config;

  if (!some([productId, productInstanceUuid])) return;

  busService.addChannel('Product', productInstanceUuid);
  busService.removeChannel('Product', productId);
};
const initBusListener = () => {
  window.addEventListener('Bus:Message', (event) => {
    if (store.state.shouldIgnoreSocketMessages) return;

    store.dispatch('handleBusMessageDetail', event.detail);
    emitter.emit(event.detail.type);
  });

  if (!isPostmessaging.value) return;
  // Only needed when the parent frame is bouncing messages
  window.addEventListener('message', (event) => {
    if (store.state.shouldIgnoreSocketMessages) return;

    if (!bouncedMessageTypesToListen.value.includes(event.data.type)) return;
    const customEvent = new CustomEvent(`Bus:Message`, {
      detail: event.data,
    });

    window.dispatchEvent(customEvent);
  });
};
const initState = async () => {
  const url = `/${store.getters.productInstanceUuid}${process.env.VUE_APP_STATE}`;
  const state = await http.get(url);
  const dataData = state.data.data;
  emitter.emit(dataData.type);
  store.dispatch('handleBusMessageDetail', {
    ...{ ...dataData, data: { ...dataData.data, type: dataData.type } },
    type: 'state',
  });
};
const getConfig = async () => {
  const config = await getConfiguration(store);
  store.dispatch('setClientConfig', config);

  return config;
};
/**
 * Checks if two dates are more than a specified number of seconds apart.
 *
 * @param {string | Date} firstDate - The first date, either as a date string or Date object.
 * @param {string | Date} secondDate - The second date, either as a date string or Date object.
 * @param {number} n - The number of seconds to compare.
 * @returns {boolean} - Returns true if the two dates are more than n seconds apart, otherwise false.
 */
const areDatesMoreThanNSecondsApart = (firstDate, secondDate, n) =>
  dateDifferenceInSeconds(firstDate, secondDate) > n;
/**
 * Handles the visibility change event. If the current time is more than a specified number
 * of seconds apart from the event start time, it initializes the state, because there has been
 * a disconnection or a message was skipped
 *
 * @function
 */
const handleVisibilityChange = () => {
  const now = new Date();
  // the current moment and start time of the event
  // are within expected limits
  const noMessagesHaveBeenSkipped = !areDatesMoreThanNSecondsApart(
    eventStartTime.value,
    now,
    lengthiestMessageDelay.value
  );

  if (noMessagesHaveBeenSkipped) return;

  initState();
};
const initServices = async () => {
  const config = await getConfig();

  if (isPostmessaging.value) return;

  initBus(config);
};

onMounted(async () => {
  await initServices();
  initBusListener();
  initState();

  window.addEventListener('visibilitychange', handleVisibilityChange);
});

onBeforeMount(() => {
  initResize(store);
});

onBeforeUnmount(() => {
  removeResize();
  window.removeEventListener('visibilitychange', handleVisibilityChange);
});
</script>
<style lang="scss">
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}
html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  font-family: 'Rubik', sans-serif, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: $primary-bg-color;
  color: $primary-text-color;
}
</style>
