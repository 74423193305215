import { isNil, defaultTo } from 'lodash';
import { formatNumber } from '@nsftx/games-visualization-sdk/src/utility';

const numberFormat = new Intl.NumberFormat('en', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatOdds = (odds) => {
  return !isNil(odds) ? numberFormat.format(odds) : '-';
};

/**
 * On smaller screens, we need to abbreviate the phase names
 * @param {*} phase
 * @param {*} screenSize
 * @returns {string}
 */
function formatPhaseName(phase, screenSize) {
  if (!phase) return '';
  const maxMobile = screenSize <= 640;

  const nameMapper = {
    semiFinalist: '1/2',
    finalist: 'F',
    winner: 'winnerShort',
  };

  return maxMobile ? nameMapper[phase] : phase;
}

/**
 * Returns the initials of a given name by abbreviating both the first and last names.
 *
 * @param {string} name - The full name to be abbreviated.
 * @returns {string} The abbreviated name with initials.
 */
const getInitials = (name) => getAbbreviatedLastName(getAbbreviatedFirstName(name));

/**
 * Abbreviates the last name in the given full name.
 *
 * @param {string} name - The full name to be abbreviated.
 * @returns {string} The name with the last name abbreviated.
 */
const getAbbreviatedLastName = (name) => {
  if (!name) return name;
  const [firstName, lastName] = name.split(' ');
  const abbreviatedLastName = lastName ? `${lastName.charAt(0)}.` : '';

  return abbreviatedLastName ? `${firstName} ${abbreviatedLastName}` : firstName;
};

/**
 * Abbreviates the first name in the given full name.
 *
 * @param {string} name - The full name to be abbreviated.
 * @returns {string} The name with the first name abbreviated.
 */
const getAbbreviatedFirstName = (name) => {
  if (!name) return name;
  const [firstName, lastName] = name.split(' ');
  const abbreviatedFirstName = firstName ? `${firstName.charAt(0)}.` : '';

  return lastName ? `${abbreviatedFirstName} ${lastName}` : abbreviatedFirstName;
};

const getLocalisedNumber = (number) => {};

export {
  formatOdds,
  formatPhaseName,
  getAbbreviatedFirstName,
  getAbbreviatedLastName,
  getInitials,
};
