import { getTimeDelay } from '@nsftx/games-visualization-sdk/src/utility';
import types from './mutationTypes';

let countdownInterval = null;
export default {
  handleBusMessageDetail({ commit, dispatch, state }, message) {
    const event = message.type;
    const { sentTime } = message;
    const eventData = message.data;
    const delay = eventData.delay || 0;
    const { raceId } = eventData;
    const eventType = eventData.type || event;

    if (eventData.videoDto) {
      eventData.video = {
        ...eventData.videoDto,
        leftCompetitor: eventData.videoDto.rightCompetitor,
        rightCompetitor: eventData.videoDto.leftCompetitor,
      };
    }

    // The state is fetched after visibilitychange in case
    // where the socket connection has been lost
    // so we don't want any late messages to override our state
    if (state.game.currentStage === eventType) return;

    let countdownTime;

    if (raceId) commit(types.SET_RACE_ID, raceId);
    if (eventType) dispatch('setStage', eventType);

    dispatch('setDelay', delay);
    dispatch('setEventStartTime', sentTime);

    commit(types.SET_INFO_MESSAGE, '');

    switch (event) {
      case 'state':
        dispatch('setGameStateMode');
        // set offer/odds
        if (eventData.offer) {
          commit(types.SET_GAME_ODDS, eventData.offer);
        }
        // set tournament id
        if (eventData.tournamentIdToday) {
          commit(types.SET_TOURNAMENT_ID_TODAY, eventData.tournamentIdToday);
        }

        if (eventType === 'countdown') {
          countdownTime = getTimeDelay(new Date(sentTime).getTime(), new Date().getTime(), delay);
          commit(types.SET_COUNTDOWN_TIME, countdownTime);
          commit(types.SET_IS_LAST_COUNTDOWN, eventData.raceId === 15);
          dispatch('setCountdownInterval');
        } else if (eventType === 'result') {
          if (eventData.winner) {
            commit(types.SET_WINNER_RESULTS, eventData.winner);
          }
          if (eventData.knockout) {
            commit(types.SET_KNOCKOUT_RESULTS, eventData.knockout);
          }
          commit(types.SET_GAME_RESULTS, eventData);
        } else if (eventType === 'race') {
          const startTime =
            delay - getTimeDelay(new Date(sentTime).getTime(), new Date().getTime(), delay);
          if (eventData.video) {
            commit(types.SET_VIDEO_ID, eventData.video.id);
            commit(types.SET_VIDEO_DATA, eventData.video);
          }
          commit(types.SET_RACE_START_TIME, startTime);
        }
        break;

      case 'countdown':
        // set offer/odds
        commit(types.SET_GAME_ODDS, eventData.offer);
        // set tournament id
        if (eventData.tournamentIdToday) {
          commit(types.SET_TOURNAMENT_ID_TODAY, eventData.tournamentIdToday);
        }
        commit(types.SET_COUNTDOWN_TIME, delay);
        commit(types.SET_EVENT_TIME, {
          type: 'countdown',
          time: delay,
        });
        dispatch('setCountdownInterval');
        break;

      case 'race':
        // set race video
        if (eventData.video) {
          commit(types.SET_VIDEO_ID, eventData.video.id);
          commit(types.SET_VIDEO_DATA, eventData.video);
        }
        commit(types.SET_INFO_MESSAGE, 'waitingForTheRace');
        // set video start to beginning
        commit(types.SET_RACE_START_TIME, 0);
        break;
      case 'result':
        if (eventData.winner) {
          commit(types.SET_WINNER_RESULTS, eventData.winner);
        }
        if (eventData.knockout) {
          commit(types.SET_KNOCKOUT_RESULTS, eventData.knockout);
        }
        commit(types.SET_GAME_RESULTS, eventData);
        break;
      default:
        break;
    }
  },
  setIsMobile({ commit }, payload) {
    commit(types.SET_IS_MOBILE, payload);
  },
  setVideoData({ commit }, payload) {
    commit(types.SET_VIDEO_DATA, payload);
  },
  setStage({ commit }, value) {
    commit(types.SET_STAGE, value);
  },
  setEventStartTime({ commit }, value) {
    commit(types.SET_EVENT_START_TIME, value);
  },
  setDelay({ commit }, value) {
    commit(types.SET_DELAY, value);
  },
  activateLoader({ commit }) {
    commit(types.SET_LOADER_STATE, true);
  },
  deactivateLoader({ commit }) {
    commit(types.SET_LOADER_STATE, false);
  },
  setClientConfig({ commit }, payload) {
    commit(types.CLIENT_CONFIG, payload);
  },
  setCountdownInterval({ commit, dispatch, getters, state }) {
    if (countdownInterval) clearInterval(countdownInterval);
    countdownInterval = setInterval(() => {
      let time = state.game.countdownTime - 1;

      if (countdownInterval && state.game.countdownTime <= 0) {
        clearInterval(countdownInterval);
        time = 0;
      }
      commit(types.SET_COUNTDOWN_TIME, time);
      if (getters.isLastCountdown && !getters.isLoaderActive) {
        dispatch('activateLoader');
      }
    }, 1000);
  },
  setGameStateMode({ commit }) {
    commit(types.SET_GAME_STATE_MODE, true);
    setTimeout(() => {
      commit(types.SET_GAME_STATE_MODE, false);
    }, 1000);
  },
  setShouldIgnoreSocketMessages({ commit }, payload) {
    commit(types.SET_SHOULD_IGNORE_SOCKET_MESSAGES, payload);
  },
  setIsLastCountdown({ commit }, payload) {
    commit(types.SET_IS_LAST_COUNTDOWN, payload);
  },
  updateTranslations({ commit }, translations) {
    commit(types.UPDATE_TRANSLATIONS, translations);
  },
};
