<template>
  <div class="results-content screen-content">
    <TransitionGroup name="fade">
      <!-- RACE WINNER SCREEN -->
      <div
        class="results-section race-winner"
        :class="{ active: activeSection === 'winner' }"
        key="winner"
      >
        <div class="screen-item">
          <div class="screen-item-header">
            <div class="id">{{ winner?.id }}</div>
            <div class="name">{{ store.getters.getTranslation(`racer_${winner.id}`) }}</div>
          </div>
          <div class="screen-item-content-top">
            <div class="title">{{ store.getters.getTranslation(winnerCurrentStage) }}</div>
            <div class="content">{{ store.getters.getTranslation('raceWinner') }}</div>
          </div>
          <div class="screen-item-content-bottom">
            <div class="title" v-if="winnerNextStage">
              <span>{{ store.getters.getTranslation('qualifiesTo') }}</span
              >&nbsp;
              <span>{{ store.getters.getTranslation(winnerNextStage) }}</span>
            </div>
            <div class="title" v-else>
              <span>{{ store.getters.getTranslation('winner') }}</span>
            </div>
            <div class="content">
              <div class="content-item">
                <div class="content-item-label">{{ store.getters.getTranslation('raceTime') }}</div>
                <div class="content-item-value">{{ formatResultTime(winner?.time) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="screen-item-image"
          :class="[{ alternate: shouldAlternate }, `racer-${getWinnerId()}`]"
        ></div>
      </div>
      <!-- KNOCKOUT STAGE SCREEN -->
      <div
        class="results-section knockout"
        :class="{ active: activeSection === 'knockout' }"
        key="knockout"
      >
        <div class="section-content">
          <div class="screen-title-wrap">
            <div class="screen-title">{{ store.getters.getTranslation('knockoutStage') }}</div>
            <div class="screen-subtitle">
              <span>{{ store.getters.getTranslation('tournamentNo') }}</span
              >&nbsp;
              <span>{{ results.tournamentIdToday }}</span>
            </div>
          </div>
          <div class="table-tree-wrap">
            <!-- Left table tree -->
            <div class="table-tree left">
              <div class="table-tree-section items-4">
                <KnockoutItem
                  v-for="(n, idx) in 4"
                  :key="idx"
                  :data="(knockout?.eighthFinals && knockout?.eighthFinals[idx]) || {}"
                />
              </div>
              <div class="table-tree-section items-2">
                <KnockoutItem
                  v-for="(n, idx) in 2"
                  :key="idx"
                  :data="(knockout.quarterFinals && knockout.quarterFinals[idx]) || {}"
                />
              </div>
              <div class="table-tree-section items-1">
                <KnockoutItem :data="(knockout.semiFinals && knockout.semiFinals[0]) || {}" />
              </div>
            </div>
            <!-- Center table tree -->
            <div class="table-tree center">
              <div class="table-tree-section items-1">
                <KnockoutItem :data="(knockout.finals && knockout.finals[0]) || {}" />
              </div>
            </div>
            <!-- Right table tree -->
            <div class="table-tree right">
              <div class="table-tree-section items-4">
                <KnockoutItem
                  v-for="(n, idx) in 4"
                  :key="idx"
                  :data="(knockout?.eighthFinals && knockout?.eighthFinals[idx + 4]) || {}"
                />
              </div>
              <div class="table-tree-section items-2">
                <KnockoutItem
                  v-for="(n, idx) in 2"
                  :key="idx"
                  :data="(knockout.quarterFinals && knockout.quarterFinals[idx + 2]) || {}"
                />
              </div>
              <div class="table-tree-section items-1">
                <KnockoutItem :data="(knockout.semiFinals && knockout.semiFinals[1]) || {}" />
              </div>
            </div>
            <!-- Winner box -->
            <div class="tournament-winner" v-if="tournamentWinner">
              <div class="winner-id">{{ tournamentWinner?.id }}</div>
              <div class="details">
                <div class="title">{{ store.getters.getTranslation('tournamentWinner') }}</div>
                <div class="winner-name">
                  {{ store.getters.getTranslation(`racer_${tournamentWinner.id}`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
// Imports
// General
import { padStart } from 'lodash';
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
// Components
import KnockoutItem from './KnockoutItem';
// Other

const shouldAlternate = Math.random() >= 0.5;
// Ref
const store = useStore();
const isScreenActive = ref(false);
const activeSection = ref();
const tournamentWinner = ref();
const shiftInt = ref();
const sections = ref(['winner', 'knockout']);
const tournamentStages = ref(['eighthFinals', 'quarterFinals', 'semiFinals', 'finals']);
// Getters
const game = computed(() => store.getters.game);
const results = computed(() => store.getters.results);
// Computed
const winner = computed(() => results.value && results.value.winner);
const winnerCurrentStage = computed(() => results.value && results.value.winner.stage);
const winnerNextStage = computed(() => {
  const currentStage = winnerCurrentStage.value;

  const currentStageIdx = tournamentStages.value.indexOf(currentStage);
  let nextStage = null;

  if (currentStageIdx > -1 && tournamentStages.value[currentStageIdx + 1]) {
    nextStage = tournamentStages.value[currentStageIdx + 1];
  }

  return nextStage;
});
const knockout = computed(() => results.value && results.value.knockout);
const sectionTime = computed(() => game.value.eventTime.results / sections.value.length - 1);
// Methods
const getTournamentWinner = () => {
  let newWinner = null;
  const finals = knockout.value && knockout.value.finals && knockout.value.finals[0];

  if (finals) {
    Object.keys(finals).forEach((competitor) => {
      if (finals[competitor].won) {
        newWinner = finals[competitor];
      }
    });
  }

  tournamentWinner.value = newWinner;
};

const getWinnerId = () => results.value.winner?.id;

const setActiveScreen = () => {
  if (!isScreenActive.value) {
    [activeSection.value] = sections.value;
    isScreenActive.value = true;
  } else {
    const activeSectionIdx = sections.value.indexOf(activeSection.value);
    if (sections.value[activeSectionIdx + 1]) {
      activeSection.value = sections.value[activeSectionIdx + 1];
    } else {
      clearInterval(shiftInt.value);
    }
  }
};

const shiftScreens = () => {
  setActiveScreen();
  shiftInt.value = setInterval(setActiveScreen, sectionTime.value * 1000);
};

const formatResultTime = (time) => {
  const sec = parseInt(time, 10);
  const hundreths = Math.round((time % sec) * 100);
  return `00:${padStart(sec, 2, '0')}:${padStart(hundreths, 2, '0')}`;
};
// Watchers
// Lifecycle hooks
onBeforeMount(() => getTournamentWinner());

onMounted(() => {
  setTimeout(shiftScreens, 2000);
});

onUnmounted(() => {
  clearInterval(shiftInt.value);
});
</script>

<style lang="scss" scoped>
.results-section {
  opacity: 0;
  transition: opacity 0.5s;

  &.active {
    opacity: 1;
  }
}

// RACE WINNER
.race-winner {
  position: relative;
  display: inline-block;
  width: 55vw;
  height: 100%;
  margin-left: 50%;
  text-align: center;
  transform: translateX(-50%) translateZ(0);
}
.screen-item {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba($primary-bg-color, 0.2);
  font-size: get-vw(16px);
  color: $secondary-text-color;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(30%) translateZ(0);
  transition: all 0.5s ease-out, opacity 1.5s;
  opacity: 0;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  overflow: hidden;
}
.active .screen-item {
  opacity: 1;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  transform: translateY(0%) translateZ(0);
  transition-delay: 0.5s;
}

.screen-item-content-top {
  .title {
    font-size: get-vw(18px);
  }
  .content {
    line-height: 1.1;
  }
}
.screen-item-content-bottom {
  .content {
    text-transform: uppercase;
  }
}
.screen-item-image {
  position: absolute;
  bottom: 20vh;
  width: get-vw(582px);
  height: get-vw(217px);
  margin-left: 50%;
  background: {
    repeat: no-repeat;
    position: 0 100%;
  }
  transform: translateX(-50%) translateZ(0);

  &.alternate {
    transform: scaleX(-1) translateX(50%) translateZ(0);
  }

  @for $i from 1 through 20 {
    &.racer-#{$i} {
      background-image: url('../assets/results/racer-#{$i}.png');
    }
  }
}

.active .screen-item-image {
  animation: verticalSprite 1.3s steps(36) forwards;
}

// KNOCKOUT STAGE
.table-tree-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: get-vw(20px);
}
.table-tree {
  position: relative;
  width: 43%;
  height: 100%;

  &.left {
    float: left;
  }
  &.right {
    float: right;
  }
  &.center {
    display: inline-block;
    width: 14%;
  }
}
.table-tree-section {
  width: 33.333%;
  height: 100%;
  transform: translateX(-50%) translateZ(0);
  transition: all 0.4s;
  opacity: 0;
}
.active .table-tree-section {
  transform: translateX(0%) translateZ(0);
  opacity: 1;
  // transition-delay: 1s;
}
.center .table-tree-section {
  width: 100%;
  transform: translateY(20%) translateZ(0);
}
.active .center .table-tree-section {
  transform: translateY(0%) translateZ(0);
}
.left {
  .table-tree-section {
    float: left;
  }
}
.active .left .table-tree-section {
  transform: translateX(0%) translateZ(0);
  &.items-1 {
    transform: translateX(get-vw(-40px)) translateZ(0);
  }
}
.right {
  .table-tree-section {
    float: right;
    transform: translateX(100%) translateZ(0);
  }
}
.active .right .table-tree-section {
  transform: translateX(0%) translateZ(0);
  &.items-1 {
    transform: translateX(get-vw(40px)) translateZ(0);
  }
}
.table-tree-section.items-4 {
  transition-delay: 0.4s;
}
.table-tree-section.items-2 {
  transition-delay: 0.8s;
}
.table-tree-section.items-1 {
  transition-delay: 1.2s;
}
.center .table-tree-section.items-1 {
  transition-delay: 1.5s;
}
.tournament-winner {
  display: table;
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%) translateZ(0);
  width: get-vw(290px);
  height: get-vw(70px);
  background: $highlight-color;
  transition: opacity 0.5s;
  transition-delay: 3s;
  opacity: 0;

  .winner-id {
    display: table-cell;
    width: 20%;
    height: 100%;
    vertical-align: middle;
    font-size: get-vw(32px);
    text-align: center;
  }
  .details {
    display: table-cell;
    width: 80%;
    height: 100%;
    vertical-align: middle;
    text-align: left;
  }
  .title {
    font-size: get-vw(16px);
    text-transform: uppercase;
  }
  .winner-name {
    font-size: get-vw(24px);
    text-transform: capitalize;
  }
}
.active .tournament-winner {
  opacity: 1;
}

@media screen and (min-width: 0px) {
  @media screen and (max-height: 270px) {
    .race-winner {
      .screen-item-image {
        bottom: 23vh;
        width: get-vw(632px);
        height: get-vw(230px);
      }
    }
  }

  .race-winner {
    .screen-item-image {
      --height: 29.8vh;
      bottom: 23vh;
      margin-left: 55%;
      height: var(--height);
      width: calc(2.65 * var(--height));

      &.alternate {
        margin-left: 45%;
      }
    }
    .screen-item {
      .screen-item-content-top {
        .title {
          font-size: get-vw(10px, 360);
        }

        .content {
          font-size: get-vw(18px, 360);
        }
      }
      .screen-item-content-bottom {
        .title {
          text-transform: uppercase;
          font-size: get-vw(10px, 360);
        }

        .content {
          .content-item-label {
            font-size: get-vw(10px, 360);
          }

          .content-item-value {
            font-size: get-vw(18px, 360);
          }
        }
      }

      .screen-item-header {
        font-size: get-vw(13px, 360);
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile-md) {
  @media screen and (max-height: 270px) {
    .race-winner {
      .screen-item-image {
        bottom: 27vh;
        width: get-vh(270px, 480);
        height: get-vh(100px, 480);
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .race-winner {
    .screen-item-image {
      --height: 32.6vh;
      box-sizing: unset;
      bottom: 24vh;
      margin-left: 50%;
      height: var(--height);
      width: calc(2.66 * var(--height));
    }

    .screen-item {
      .screen-item-content-top {
        .title {
          font-size: get-vw(12px, 640);
        }

        .content {
          font-size: get-vw(32px, 640);
        }
      }

      .screen-item-content-bottom {
        .title {
          text-transform: uppercase;
          font-size: get-vw(12px, 640);
        }

        .content {
          .content-item-label {
            font-size: get-vw(12px, 640);
          }

          .content-item-value {
            font-size: get-vw(24px, 640);
          }
        }
      }

      .screen-item-header {
        font-size: get-vw(24px, 640);
      }
    }
  }
}
</style>
