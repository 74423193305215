import { ConfigurationService } from '@nsftx/games-config';
import i18n from '@/i18n';
import { assign } from 'lodash';

const getConfiguration = async (store) => {
  let config;
  try {
    const params = new URLSearchParams(window.location.search);
    const appName = params.get('application');
    const tenantId = params.get('tenantId') || params.get('company');
    const configServiceObject = {
      clientAdapters: [
        {
          name: 'GamesHttpAdapterV2',
        },
      ],
      environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
      productName: 'VirtualDragRaces',
      channel: 'WEB',
      applicationName: appName || 'web',
      ui: tenantId,
    };
    const configService = await new ConfigurationService(configServiceObject);
    config = await configService.getConfiguration();
    console.log('config', config);
    const defaultRacers = {
      racer_1: 'Clint Hines',
      racer_2: 'Boris Dean',
      racer_3: 'Gary Rios',
      racer_4: 'Clark Willis',
      racer_5: 'Lester Fisher',
      racer_6: 'Kenny Lee',
      racer_7: 'Jason Looks',
      racer_8: 'Jason Blind',
      racer_9: 'Milton Jones',
      racer_10: 'Kim Park',
      racer_11: 'David Stokes',
      racer_12: 'Jody Horton',
      racer_13: 'Eddie Holmes',
      racer_14: 'Archie Barton',
      racer_15: 'Gary Wells',
      racer_16: 'Roland Foster',
      racer_17: 'Casey Lowe',
      racer_18: 'Evan Walker',
      racer_19: 'Eddie Cook',
      racer_20: 'Alex Luna',
    };
    const racers = config?.ui?.config?.vdr?.racers || defaultRacers;
    console.log('racers', racers);

    const translationsApp = (await i18n(config?.locale || 'en')).default;
    await store.dispatch('updateTranslations', assign(racers, translationsApp));

    if (!config) {
      console.log('Please try later no config data, config ERROR');
    }
  } catch (e) {
    console.log('Please try later no config data, try ERROR');
  }
  return config;
};

export default getConfiguration;
