import { createApp } from 'vue';
import mitt from 'mitt';
import { isMobile } from '@nsftx/games-visualization-sdk/src/utility';
import App from './App';
import store from './store';

store.dispatch('setIsMobile', isMobile().any);

const app = createApp(App);
const emitter = mitt();

app.provide('emitter', emitter);
app.use(store).mount('#app');
