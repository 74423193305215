export default {
  STATE: 'state',
  RACE: 'race',
  RESULT: 'result',
  COUNTDOWN: 'countdown',
  BETTING_DISABLED: 'bettingDisabled',
  START_RACE: 'StartRace',
  START_COUNTDOWN: 'StartCountdown',
  SET_RESULTS: 'SetResults',
  STOP_BETTING: 'StopBetting',
};
