import { BusService } from '@nsftx/games-bus';

/**
 * Inits and returns the Bus service
 * @param {*} config
 * @returns busService
 */
const initBusService = (config) => {
  let busService;

  try {
    busService = new BusService({
      environment: config.environment,
      platform: config.platform,
      productName: config.productName,
      productId: config.productId,
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      useCommonEventName: true,
      adapters: [{ name: 'PusherAdapter', options: { key: process.env.VUE_APP_PUSHER_ID } }],
    });

    window.busService = busService;
    busService.start();
  } catch (error) {
    console.error('Bus service error', error);
  }

  return busService;
};

export default initBusService;
