<template>
  <div v-if="data" class="table-tree-item">
    <div
      v-for="(n, idx) in 2"
      :key="idx"
      class="item-content"
      :class="{
        inactive: !data['competitor' + n],
        active: data['competitor' + n] && data['competitor' + n].won,
      }"
    >
      <div class="item-content-left">
        {{ (data['competitor' + n] && data['competitor' + n].id) || '' }}
      </div>
      <div class="item-content-right">
        {{
          (data['competitor' + n] &&
            store.getters.getTranslation(`racer_${data['competitor' + n].id}`)) ||
          ''
        }}
      </div>
    </div>
  </div>
  <div v-else class="table-tree-item">
    <div class="item-content inactive"></div>
    <div class="item-content inactive"></div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

defineProps({
  data: {
    type: Object,
    required: true,
    default: () => {},
  },
});
</script>
<style lang="scss" scoped>
.table-tree-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 25%;

  .item-content {
    display: table;
    table-layout: fixed;
    width: get-vw(156px);
    height: get-vw(56px);
    margin: 0.5vh 0;
    background: rgba($primary-bg-color, 0.6);
    font-size: get-vw(18px);
    opacity: 0.4;
    border-radius: 3px;
    overflow: hidden;

    &.active {
      opacity: 1;
    }
    &.inactive {
      border: 1px solid $highlight-color;
      > div {
        display: none;
      }
    }
    > div {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      // font-size: get-vw(18px);
      white-space: normal;
    }
    .item-content-left {
      width: 23%;
      text-align: center;
      background: $highlight-color;
    }
    .item-content-right {
      width: 77%;
      padding: 0 get-vw(8px);
    }
  }
}
.items-4 .table-tree-item {
  height: 25%;
}
.items-2 .table-tree-item {
  height: 50%;
}
.items-1 .table-tree-item {
  height: 100%;
}
.center .table-tree-item {
  align-items: center;
}
.right .table-tree-item {
  align-items: flex-end;
}
</style>
