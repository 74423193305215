<template>
  <div class="container">
    <AppHeader
      :name="product.name"
      :title="translations.productName"
      :infoMessage="translate(infoMessage)"
      :loaderActive="isLoaderActive"
      :loaderCountdownActive="isLastCountdown"
      :eventId="raceId"
      :roundInfo="roundInfoComputed"
      :nextRoundInfo="nextRoundInfoComputed"
      :countdownTime="countdownTime"
      :progressStartValue="progressStartValue"
      :progressFullWidth="progressFullWidth"
      :clientLogo="clientLogo"
    />
    <AppContent />
  </div>
</template>

<script setup>
// Imports
// General
import { ref, computed, onMounted, onUnmounted, inject } from 'vue';
import { useStore } from 'vuex';
// Other
import { translate } from '@nsftx/games-visualization-sdk/src/utility';
import { delay } from '@/utility';
// Components
import AppHeader from '@nsftx/games-visualization-sdk/src/components/AppHeader';
import AppContent from './AppContent';

// Ref
const emitter = inject('emitter');
const store = useStore();
const progressFullWidth = ref(false);
const progressStartValue = ref(0);
const showRoundInfo = ref(false);
const showNextRoundInfo = ref(false);

// Getters
const clientLogo = computed(() => store.getters.isLogoEnabled && store.getters.config.logo.path);
const raceId = computed(() => store.getters.raceId);
const countdownTime = computed(() => store.getters.countdownTime);
const isLastCountdown = computed(() => store.getters.isLastCountdown);
const isLoaderActive = computed(() => store.getters.isLoaderActive);
const product = computed(() => store.getters.product);
const infoMessage = computed(() => store.getters.infoMessage);
const translations = computed(() => store.getters.translations);
const getTranslationWithInterpolation = computed(
  () => store.getters.getTranslationWithInterpolation
);

// Computed
const roundInfoComputed = computed(() => ({
  active: showRoundInfo.value,
  text: translations.value.roundNumber,
}));

const nextRoundInfoComputed = computed(() => ({
  active: showNextRoundInfo.value,
  text: getTranslationWithInterpolation.value('roundStartsIn', [raceId.value]),
}));

// Methods
const loaderReactivate = async (delayDuration) => {
  store.dispatch('activateLoader');

  await delay(delayDuration || 0);
  if (!store.getters.isStateMode && !store.getters.isLastCountdown) {
    store.dispatch('deactivateLoader');
  }
};

const onCountdownHandler = () => {
  loaderReactivate(1500);
  progressFullWidth.value = false;
  showRoundInfo.value = false;
  showNextRoundInfo.value = true;
  progressStartValue.value = countdownTime.value;
};

const onRaceOrResultHandler = () => {
  loaderReactivate(1500);
  progressFullWidth.value = true;
  showRoundInfo.value = true;
  showNextRoundInfo.value = false;
};

// Watchers
emitter.on('countdown', onCountdownHandler);
emitter.on('race', onRaceOrResultHandler);
emitter.on('result', onRaceOrResultHandler);

// Lifecycle hooks
onMounted(async () => {
  setTimeout(() => {
    if (isLastCountdown.value) return;
    store.dispatch('deactivateLoader');
  }, 2000);
});
onUnmounted(() => emitter.all.clear());
</script>

<style lang="scss">
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background: {
    image: url('../assets/bg.png');
    repeat: no-repeat;
    size: 100% 100%;
    position: center;
  }
}

.screen-content {
  z-index: 1;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  z-index: 0;
}
</style>
