/**
 * Gets value of requested URL param
 *
 * @param  {string} key
 * @return {string|object} Param value
 */
const getQueryParam = (key) => {
  const name = key.replace(/\[\[\]/, '\\[').replace(/\[\]\]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(window.location.href);
  let param;

  if (!results) {
    param = null;
  } else if (!results[2]) {
    param = true;
  } else {
    param = window.decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return param;
};

export default getQueryParam;
