import { merge, each } from 'lodash';
import { socketMessageTypes, raceIds } from '@/enum';

export default {
  debugMode: (state) => state.debugMode,
  messaging: (state) => state.messaging,
  /**
   * This getter checks if the messaging type is 'postmessage'.
   
   * This means that the parent frame is bouncing socket messages to the iframe
   * in which the visualization is running.
   *
   * @param {Object} state - The Vuex state object.
   * @param {Object} getters - The Vuex getters object.
   * @returns {boolean} - Returns true if the messaging type is 'postmessage', otherwise false.
   */
  isPostmessaging: (_, getters) => getters.messaging === 'postmessage',
  config: (state) => merge({}, state.config, state.cmsConfig),
  product: (state) => state.product,
  getTranslation: (state) => (key) => state.translations?.[key] || key,
  translations: (state) => state.translations,
  language: (state) => state.language,
  game: (state) => state.game,
  screenSize: (state) => state.screenSize,
  ui: (state) => state.ui,
  isLogoEnabled(state) {
    return (
      state.config.logo.enabled &&
      state.product.deliveryPlatform === 'retail' &&
      state.product.deliveryPlatform === 'terminal'
    );
  },
  /**
   * Returns a function that translates a key with interpolation.
   *
   * @param {object} _ - Placeholder parameter.
   * @param {object} getters - An object containing getter functions.
   * @param {Function} getters.getTranslation - Function that retrieves the translation for a given key.
   * @returns {Function} A function that takes a translation key and an array of parameters, and returns the interpolated translation string.
   */
  getTranslationWithInterpolation:
    (_, getters) =>
    /**
     * Translates a key with interpolation.
     *
     * @param {string} key - The translation key.
     * @param {Array<string>} [params=[]] - The parameters to interpolate into the translation.
     * @returns {string} The translated string with interpolations applied, or the key if no translation is found.
     */
    (key, params = []) => {
      let trans = getters.getTranslation(key);
      if (trans === key) return trans;

      each(params, (value) => {
        trans = trans.replace(/{{[a-zA-Z0-9-_/]*}}/g, value);
      });

      return trans;
    },
  isPenultimateRoundResultsStage: (_, { isPenultimateRound, isResultsStage }) =>
    isPenultimateRound && isResultsStage,
  lengthiestMessageDelay: (state, getters) =>
    state.lengthiestMessageDelayPerStage[getters.currentStage] || 60,
  isPenultimateRound: (_, getters) => getters.raceId === raceIds.PENULTIMATE_ROUND_ID,
  isFinalRound: (_, { raceId }) => raceId === raceIds.FINAL_ROUND_ID,
  isResultsStage: (_, { currentStage }) =>
    [socketMessageTypes.RESULT, socketMessageTypes.SET_RESULTS].includes(currentStage),
  isFinalRoundResultsStage: (_, { isFinalRound, isResultsStage }) => isFinalRound && isResultsStage,
  raceId: (state) => state.game.raceId,
  currentStage: (state) => state.game.currentStage,
  countdownTime: (state) => state.game.countdownTime,
  lastCountdownTime: (state) => state.config.lastCountdownTime,
  gameOffer: (state) => state.game.offer,
  results: (state) => state.game.results,
  winnerResult: (state) => state.game.winner,
  previousResults: (state) => state.game.previousResults,
  knockoutResults: (state) => state.game.knockout,
  videoId: (state) => state.game.videoId,
  tournamentIdToday: (state) => state.game.tournamentIdToday,
  raceStartTime: (state) => state.game.raceStartTime,
  betsConfig: (state) => state.config.game.bets,
  betsMap: (state) => state.config.game.betsMap,
  isLoaderActive: (state) => state.ui.isLoaderActive,
  isLastCountdown: () => false,
  bgVideo: (state) => state.ui.bgVideo,
  isStateMode: (state) => state.game.stateMode,
  infoMessage: (state) => state.ui.infoMessage,
  productInstanceUuid: (state) => state.clientConfig.productInstanceUuid,
};
