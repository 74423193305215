/**
 * Ensures that string type boolean behaves as real boolean
 *
 * @param  {string} string
 * @return {boolean}
 */
const stringToBoolean = (string) => {
  if (!string) return false;

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case null:
      return false;

    default:
      return Boolean(string);
  }
};

export default stringToBoolean;
