/**
 * Calculates the difference between two dates in seconds.
 *
 * @param {string | Date} firstDate - The first date, either as a date string or Date object.
 * @param {string | Date} secondDate - The second date, either as a date string or Date object.
 * @returns {number} - The difference between the two dates in seconds.
 */
const dateDifferenceInSeconds = (firstDate, secondDate) => {
  const timestamp1 = new Date(firstDate).getTime();
  const timestamp2 = new Date(secondDate).getTime();

  const differenceInMilliseconds = Math.abs(timestamp1 - timestamp2);

  return differenceInMilliseconds / 1000;
};

export default dateDifferenceInSeconds;
