import { isNil } from 'lodash';
import getQueryParam from './queryParam';
import toBoolean from './toBoolean';

const company =
  getQueryParam('company') ||
  getQueryParam('tenantId') ||
  getQueryParam('companyUuid') ||
  'b99752b3-443c-4c80-b559-945a95c4b805';
const messaging = getQueryParam('messaging');
const language = getQueryParam('lang') || getQueryParam('language') || 'en';
const deliveryPlatform =
  getQueryParam('deliveryPlatform') ||
  getQueryParam('deliveryChannel') ||
  getQueryParam('application') ||
  'web';
const environment = getQueryParam('env') || getQueryParam('environment') || 'staging';
const channel =
  getQueryParam('channel') ||
  getQueryParam('productId') ||
  getQueryParam('cpvUuid') ||
  '79bbfe91-4524-4d76-a25a-df3560c2dcff';
const showcaseLoop = getQueryParam('showcaseLoop');
const bgVideo = toBoolean(getQueryParam('bgVideo'));
const debug = toBoolean(getQueryParam('debug') || getQueryParam('debugMode'));

export default {
  app: {
    companyLanguage: false,
    lastCountdownTime: 5,
    videoFormat: 'mp4',
    logo: {
      enabled: false,
      path: null,
      useDefault: false,
      image: {
        preferredSize: {
          width: 150,
          height: 25,
        },
        offset: {
          x: 10,
          y: 0,
        },
      },
    },
    showcaseLoop: !isNil(showcaseLoop) ? toBoolean(showcaseLoop) : true,
    videosPath: 'https://eu-central-1.linodeobjects.com/games-encoded-storage/games-videos',
    bgVideo: !isNil(bgVideo) ? bgVideo : true,
    debug: !isNil(debug) ? debug : false,
    messaging,
    translations: {},
    language,
  },
  product: {
    name: 'VirtualDragRaces',
    title: 'Virtual Drag Races',
    environment,
    deliveryPlatform,
    channel,
    company,
  },
};
